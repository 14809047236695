<template>
  <div>
    <section class="p-data-operation">
      <h2 class="p-data-operation__heading">{{ __('terms.dataSelection') }}</h2>
      <div class="p-data-operation__content">
        <div v-for="(attribute, i) in data.attributes" :key="attribute.name" class="p-data-operation__item">
          <div class="p-data-operation__header">
            <h3 class="p-data-operation__title">{{ attribute.label }}</h3>
            <div class="p-data-operation__allSelectButton c-button c-button--small" role="button">
              <div @click="selectAll(attribute.name)" class="c-button__container">{{ __('terms.selectAll') }}</div>
            </div>
            <div class="p-data-operation__allDeselectButton c-button c-button--small c-button--attention" role="button">
              <div @click="deselectAll(attribute.name)" class="c-button__container">{{ __('terms.clear') }}</div>
            </div>
          </div>
          <div class="p-data-operation__body">
            <label v-for="(value, j) in attribute.values" :key="value.id" class="p-data-operation__checkbox">
              <div class="c-checkbox">
                <input
                  v-if="checked[attribute.name].length"
                  v-model="checked[attribute.name][j].checked"
                  type="checkbox"
                />
                <div class="c-checkbox__frame"></div>
              </div>
              {{ value.value }}
            </label>
          </div>
        </div>
      </div>
    </section>

    <section class="p-data-summary mt-8">
      <h2 class="p-data-summary__heading">{{ __('terms.dataAggregation') }}</h2>
      <ul class="p-data-summary__selectBoxes">
        <li class="p-data-summary__selectBox">
          <label class="c-typo--bodyBold">{{ __('terms.base') }}</label>
          <div class="c-select">
            <select v-model="yattr" class="c-typo--subBodyBold">
              <option v-for="attribute in yattrs" :value="attribute.name" :key="attribute.name">
                {{ attribute.label }}
              </option>
            </select>
            <div class="c-select__icon">
              <span class="c-icon"> chevron_downward </span>
            </div>
          </div>
        </li>
        <li class="p-data-summary__selectBox">
          <label class="c-typo--bodyBold">{{ __('terms.class') }}</label>
          <div class="c-select">
            <select v-model="xattr" class="c-typo--subBodyBold">
              <option v-for="attribute in xattrs" :value="attribute.name" :key="attribute.name">
                {{ attribute.label }}
              </option>
            </select>
            <div class="c-select__icon">
              <span class="c-icon"> chevron_downward </span>
            </div>
          </div>
        </li>
        <li class="p-data-summary__selectBox">
          <label class="c-typo--bodyBold">{{ __('terms.dataValue') }}</label>
          <div class="c-select">
            <select v-model="dattr" class="c-typo--subBodyBold">
              <option
                v-for="attribute in [{ name: 'all', label: __('terms.total') }, ...dattrs]"
                :value="attribute.name"
                :key="attribute.name"
              >
                {{ attribute.label }}
              </option>
            </select>
            <div class="c-select__icon">
              <span class="c-icon"> chevron_downward </span>
            </div>
          </div>
        </li>
      </ul>

      <table v-if="filteredData && filteredData.length" class="p-data-summary__table">
        <thead class="p-data-summary__table__header">
          <tr class="p-data-summary__table__row">
            <th class="p-data-summary__table__item isBlank"></th>
            <th v-for="(attr, i) in xdata" :key="i" class="p-data-summary__table__item">
              {{ attr.value }}
            </th>
          </tr>
        </thead>
        <tbody class="p-data-summary__table__body">
          <tr v-for="(attr, i) in ydata" class="p-data-summary__table__row">
            <th class="p-data-summary__table__item">{{ attr.value }}</th>
            <td v-for="(detail, j) in getSummaryDetail(i)" :key="j" class="p-data-summary__table__item">
              <ul v-for="(val, key) in detail" :key="key" class="p-data-summary__table__itemList">
                <li>{{ `${key}:${val}` }}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <section class="p-data mt-8">
      <h2 class="p-data__heading">{{ __('terms.data') }}</h2>
      <table class="p-data__table">
        <thead class="p-data__table__header">
          <tr class="p-data__table__row">
            <th
              v-for="(attribute, i) in data.attributes"
              @click="handleClickSort(attribute.name)"
              :key="attribute.name"
              class="p-data__table__item"
            >
              {{ attribute.label }}
              <span class="p-data__table__marker" v-if="sortAttr == attribute.name">{{ sortMarker }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="p-data__table__body">
          <tr v-for="(rows, i) in sortedData" class="p-data__table__row">
            <td v-for="(val, key) in rows" class="p-data__table__item">
              {{ getValueFromAttr(val, key).value }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>
<script>
console.log('stats clasroom basic');

import { __ } from '../lib/i18n';

export default {
  data() {
    return {
      checked: {
        project: [],
        user: [],
        role: [],
        task: [],
        document: [],
        mission: [],
      },
      filteredData: null,
      attrs: [],
      xattr: 'role',
      yattr: 'user',
      dattr: 'all',
      sortAttr: 'project',
      sortOrder: 1,
    };
  },
  computed: {
    xdata() {
      return this.getUniqueFilteredAttr(this.xattr);
    },
    ydata() {
      return this.getUniqueFilteredAttr(this.yattr);
    },
    xattrs() {
      return this.attrs.filter((v) => v.name !== this.yattr && v.name !== this.dattr);
    },
    yattrs() {
      return this.attrs.filter((v) => v.name !== this.xattr && v.name !== this.dattr);
    },
    dattrs() {
      return this.attrs.filter((v) => v.name !== this.xattr && v.name !== this.yattr);
    },
    sortMarker() {
      return this.sortOrder === 1 ? '▲' : '▼';
    },
    sortedData() {
      if (this.filteredData) {
        return [...this.filteredData].sort((a, b) => {
          const aName = this.getValueFromAttr(a[this.sortAttr], this.sortAttr).value;
          const bName = this.getValueFromAttr(b[this.sortAttr], this.sortAttr).value;
          if (aName < bName) return this.sortOrder * -1;
          if (aName > bName) return this.sortOrder;
          return 0;
        });
      }
    },
  },
  watch: {
    checked: {
      handler: function () {
        this.filteredData = this.data.rows.filter((v) => {
          return Object.keys(v).every((key) => {
            return this.checked[key].find((checkItem) => checkItem.id === v[key]).checked;
          });
        });
      },
      deep: true,
    },
  },
  mounted() {
    // TODO: JSON API とつなぎこむ
    // console.log(this.data.attributes);
    // console.log(this.data.rows);

    this.data.attributes.forEach((v) => {
      this.checked[v.name] = v.values.map((v) => {
        return { id: v.id, checked: true };
      });
      this.attrs.push({ name: v.name, label: v.label });
    });
  },
  methods: {
    __(key) {
      return __(key);
    },
    selectAll(name) {
      for (const checkItem of this.checked[name]) {
        checkItem.checked = true;
      }
    },
    deselectAll(name) {
      for (const checkItem of this.checked[name]) {
        checkItem.checked = false;
      }
    },
    getValueFromAttr(val, key) {
      return this.data.attributes.find((v) => v.name === key).values.find((v) => v.id === val);
    },
    getUniqueFilteredAttr(name) {
      if (this.filteredData) {
        const result = [...new Set(this.filteredData.map((v) => v[name]))]
          .map((v) => this.getValueFromAttr(v, name))
          .sort((a, b) => {
            return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
          });
        return result;
      }
    },
    getSummaryDetail(index) {
      const ydata = this.filteredData.filter((data) => data[this.yattr] === this.ydata[index].id);
      return this.xdata.map((v, i) => {
        return this.countDetail(ydata.filter((data) => data[this.xattr] === this.xdata[i].id));
      });
    },
    countDetail(data) {
      const result = {};
      if (this.dattr === 'all') {
        return { 合計: data.length };
      }
      for (let v of data) {
        const key = this.getValueFromAttr(v[this.dattr], this.dattr).value;
        if (!result[key]) result[key] = 0;
        result[key] += 1;
      }
      return result;
    },
    handleClickSort(name) {
      if (name == this.sortAttr) {
        this.sortOrder = this.sortOrder * -1;
      }
      this.sortAttr = name;
    },
  },

  props: {
    data: Object,
  },
};
</script>
