/**
 * クラスルーム登録者の選択 UI用のモジュール
 * data attribute 経由で minhon.member.js の変更を受け取り、 input タグを更新する
 */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['userIds'];

  connect() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-selected-users') this.update();
      });
    });

    observer.observe(this.observeEl, { attributes: true });

    this.observer = observer;
    this.update();
  }

  get observeEl() {
    return this.element.querySelector('#tabs_org');
  }

  update() {
    const containerEl = this.userIdsTarget;
    while (containerEl.hasChildNodes()) {
      containerEl.removeChild(containerEl.lastChild);
    }
    const userIds = JSON.parse(this.observeEl.dataset.selectedUsers);
    userIds.forEach((userId) => {
      const el = document.createElement('input');
      el.type = 'hidden';
      el.name = 'userIds[]';
      el.value = userId;
      containerEl.appendChild(el);
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
