import MicroModal from 'micromodal';
import React, { useEffect, useRef } from 'react';
const Modal = (props) => {
    const { id, title = '', open = false, children } = props;
    const modalElRef = useRef();
    useEffect(() => {
        if (open) {
            MicroModal.show(id, { openClass: 'c-modal--open' });
        }
        else {
            if (modalElRef.current && modalElRef.current.classList.contains('c-modal--open')) {
                MicroModal.close(id);
            }
        }
    }, [open]);
    return (React.createElement("div", { className: "c-modal", id: id, "aria-hidden": "true", ref: modalElRef },
        React.createElement("div", { className: "c-modal__overlay", "tab-index": "-1" },
            React.createElement("div", { className: "c-modal__container", role: "dialog", "aria-modal": "true" },
                React.createElement("h2", { className: "c-modal__title c-typo--heading2" }, title),
                React.createElement("div", { className: "c-modal__contents" }, children)))));
};
export default Modal;
