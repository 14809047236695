import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dict', 'noDictMessage'];

  connect() {
    this.update();
  }

  get state() {
    return JSON.parse(this.data.get('state'));
  }

  set state(state) {
    this.data.set('state', JSON.stringify(state));
    this.update();
  }

  changeSourceLang(e) {
    this.state = { ...this.state, sourceLang: e.target.value };
  }

  changeTargetLang(e) {
    this.state = { ...this.state, targetLang: e.target.value };
  }

  update() {
    const { sourceLang, targetLang } = this.state;
    let count = 0;
    this.dictTargets.forEach((el) => {
      if (el.dataset.sourceLang === sourceLang && el.dataset.targetLang === targetLang) {
        count++;
        el.classList.remove('hidden');
      } else {
        el.classList.add('hidden');
      }
    });

    if (count === 0) {
      this.noDictMessageTarget.classList.remove('hidden');
    } else {
      this.noDictMessageTarget.classList.add('hidden');
    }
  }
}
