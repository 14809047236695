import React, { useEffect, useState } from 'react';
import Chart from './chart';
export const Popup = (props) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        window.opener.postMessage({ action: 'ready' });
        window.addEventListener('message', function (e) {
            console.log(e.data);
            if (e.data.action === 'update') {
                setData(e.data.payload);
            }
        });
    }, []);
    if (!data)
        return null;
    return (React.createElement("div", { style: { padding: 16 } },
        React.createElement(Chart, { panel: data.panel, base: data.base, classes: data.classes, data: data.data, frequency: data.frequency, graphType: data.graphType })));
};
