import MicroModal from 'micromodal';
import { Controller } from 'stimulus';

export default class extends Controller {
  get modalId() {
    return this.data.get('id');
  }

  open() {
    MicroModal.show(this.modalId, { openClass: 'c-modal--open' });
  }

  close() {
    MicroModal.close(this.modalId);
  }
}
