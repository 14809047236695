import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/button';
import Modal from '../../components/modal';
import { uploadProjectFile } from '../../lib/api';
import { __ } from '../../lib/i18n';
const DeliverableUploader = (props) => {
    const { projectId, deliverables: initialDeliverables } = props;
    const [deliverables, setDeliverables] = useState(initialDeliverables);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const fileInputRef = useRef();
    useEffect(() => {
        if (dialogOpen) {
            setError('');
            setTitle('');
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    }, [dialogOpen]);
    const handleChangeTitle = (e) => {
        setTitle(e.target.value);
    };
    const handleClickRegister = () => {
        setDialogOpen(true);
    };
    const handleClickCancel = () => {
        setDialogOpen(false);
    };
    const handleClickSubmit = async () => {
        const fileInput = fileInputRef.current;
        if (!fileInput || !fileInput.files[0] || !title)
            return;
        const file = fileInput.files[0];
        setSubmitting(true);
        const result = await uploadProjectFile(projectId, title, file, 'deliverable');
        setSubmitting(false);
        result.match((response) => {
            setDialogOpen(false);
            setDeliverables([...deliverables, response.data]);
        }, (err) => setError(err.message));
    };
    const items = deliverables.map((deliverable) => {
        return (React.createElement("div", { key: deliverable.id, className: "flex items-center mb-4" },
            React.createElement("div", { className: "c-typo--subBodyBold flex-1" },
                React.createElement("a", { className: "c-color--themeDark", href: deliverable.url }, deliverable.title)),
            React.createElement("div", { className: "c-typo--subBodyBold flex-1" }, deliverable.username)));
    });
    return (React.createElement("div", null,
        items,
        React.createElement("div", { className: "flex justify-center mt-10" },
            React.createElement(Button, { onClick: handleClickRegister },
                React.createElement("span", { className: "c-icon" }, "add"),
                __('terms.register'))),
        React.createElement(Modal, { id: "addDeliverableModal", title: "\u6700\u7D42\u6210\u679C\u7269\u767B\u9332", open: dialogOpen },
            error && (React.createElement("div", { className: "alert alert-danger" },
                React.createElement("ul", null,
                    React.createElement("li", null, error)))),
            React.createElement("div", { className: "c-formGroup" },
                React.createElement("div", null,
                    React.createElement("label", { className: "c-label c-typo--bodyBold" },
                        __('terms.name'),
                        React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                            " ",
                            __('terms.required')))),
                React.createElement("input", { type: "text", className: "form-control", name: "title", value: title, onChange: handleChangeTitle })),
            React.createElement("div", { className: "c-formGroup" },
                React.createElement("div", null,
                    React.createElement("label", { className: "c-label c-typo--bodyBold" },
                        __('terms.file'),
                        React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                            " ",
                            __('terms.required')))),
                React.createElement("input", { type: "file", ref: fileInputRef })),
            React.createElement("div", { className: "flex justify-end" },
                React.createElement(Button, { outlined: true, className: "mr-4", onClick: handleClickCancel }, __('terms.cancel')),
                React.createElement(Button, { onClick: handleClickSubmit, submitting: submitting }, __('terms.register'))))));
};
export default DeliverableUploader;
