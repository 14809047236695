import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import Modal from '../../components/modal';
import { uploadAsset } from '../../lib/api';
import { __ } from '../../lib/i18n';
const MissionAssetUploader = (props) => {
    const { assets: initialAssets } = props;
    const [assets, setAssets] = useState(initialAssets);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const fileInputRef = useRef();
    useEffect(() => {
        if (dialogOpen) {
            setError('');
            setTitle('');
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    }, [dialogOpen]);
    const handleChangeTitle = (e) => {
        setTitle(e.target.value);
    };
    const handleClickRegister = () => {
        setDialogOpen(true);
    };
    const handleClickCancel = () => {
        setDialogOpen(false);
    };
    const handleClickSubmit = async () => {
        const fileInput = fileInputRef.current;
        if (!fileInput || !fileInput.files[0] || !title)
            return;
        const file = fileInput.files[0];
        setSubmitting(true);
        const result = await uploadAsset(title, file);
        setSubmitting(false);
        result.match((response) => {
            setDialogOpen(false);
            setAssets([...assets, response.data]);
        }, (err) => setError(err.message));
    };
    const items = assets.map((asset) => {
        const handleClickRemove = () => {
            setAssets((assets) => assets.filter(({ id }) => asset.id !== id));
        };
        return (React.createElement("div", { className: "flex items-center", style: { cursor: 'pointer' } },
            React.createElement("input", { type: "hidden", name: "assets[]", value: asset.id }),
            React.createElement("span", null, asset.title),
            React.createElement("span", { onClick: handleClickRemove },
                React.createElement(Icon, { size: 24, color: "var(--color-attention-primary)" }, "close"))));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex" },
            React.createElement("span", { className: "inline-flex items-center", style: { width: 120 } }, __('terms.data')),
            React.createElement("div", { className: "flex" }, items)),
        React.createElement(Button, { small: true, onClick: handleClickRegister }, "\u65B0\u898F\u767B\u9332"),
        React.createElement(Modal, { id: "addAssetModal", title: "\u30C7\u30FC\u30BF\u767B\u9332", open: dialogOpen },
            error && (React.createElement("div", { className: "alert alert-danger" },
                React.createElement("ul", null,
                    React.createElement("li", null, error)))),
            React.createElement("div", { className: "c-formGroup" },
                React.createElement("div", null,
                    React.createElement("label", { className: "c-label c-typo--bodyBold" },
                        __('terms.name'),
                        React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                            " ",
                            __('terms.required')))),
                React.createElement("input", { type: "text", className: "form-control", name: "title", value: title, onChange: handleChangeTitle })),
            React.createElement("div", { className: "c-formGroup" },
                React.createElement("div", null,
                    React.createElement("label", { className: "c-label c-typo--bodyBold" },
                        __('terms.file'),
                        React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                            " ",
                            __('terms.required')))),
                React.createElement("input", { type: "file", ref: fileInputRef })),
            React.createElement("div", { className: "flex justify-end" },
                React.createElement(Button, { outlined: true, className: "mr-4", onClick: handleClickCancel }, __('terms.cancel')),
                React.createElement(Button, { onClick: handleClickSubmit, submitting: submitting }, __('terms.register'))))));
};
export default MissionAssetUploader;
