import React from 'react';
export const Switch = (props) => {
    const { values, labels, value, onChange } = props;
    const selectedIndex = values.indexOf(value);
    return (React.createElement("div", { style: {
            height: 32,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid var(--color-theme-dark)',
            borderRadius: 10,
        } },
        React.createElement("span", { style: {
                boxSizing: 'content-box',
                padding: '0 16px',
                borderRadius: '10px 0 0 10px',
                color: selectedIndex === 0 ? 'var(--color-base-bright)' : 'var(--color-theme-dark)',
                backgroundColor: selectedIndex === 0 ? 'var(--color-theme-dark)' : 'inherit',
                cursor: selectedIndex === 0 ? 'default' : 'pointer',
            }, onClick: () => selectedIndex !== 0 && onChange(values[0]) }, labels[0]),
        React.createElement("span", { style: {
                boxSizing: 'content-box',
                padding: '0 16px',
                borderRadius: '0 10px 10px 0',
                color: selectedIndex === 1 ? 'var(--color-base-bright)' : 'var(--color-theme-dark)',
                backgroundColor: selectedIndex === 1 ? 'var(--color-theme-dark)' : 'inherit',
                cursor: selectedIndex === 1 ? 'default' : 'pointer',
            }, onClick: () => selectedIndex !== 1 && onChange(values[1]) }, labels[1])));
};
