import { createElement } from 'react';
import { render } from 'react-dom';
import { Controller } from 'stimulus';
import { Popup } from '../modules/metalang_stats/popup';

export default class extends Controller {
  static targets = ['root'];

  connect() {
    const props = JSON.parse(this.rootTarget.dataset.moduleProps);

    render(createElement(Popup, props), this.rootTarget);
  }
}
