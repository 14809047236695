import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ImageLang from 'images/lang.svg';
const StyledSelect = styled.select `
  background-image: url(${ImageLang});
  background-repeat: no-repeat;
  background-position: left 8px center;
  padding: 4px 4px 4px 24px;
  border: 1px solid var(--color-theme-dark);
  color: var(--color-theme-dark);
`;
const Footer = (props) => {
    const { locale } = props;
    const handleChange = async (e) => {
        await axios.put('/api/locale', { locale: e.target.value });
        location.reload();
    };
    // see also: https://mt-auto-minhon-mlt.ucri.jgn-x.jp/content/help/detail.html?q_pid=FAQ_ETC
    return (React.createElement("div", { className: "flex justify-end items-center px-6", style: {
            backgroundColor: 'var(--color-base-pale)',
            height: 48,
        } },
        React.createElement("a", { href: "/about", className: "mr-2 underline c-color--baseDeep" }, "\u307F\u3093\u306A\u306E\u7FFB\u8A33\u5B9F\u7FD2\u306B\u3064\u3044\u3066"),
        React.createElement(StyledSelect, { style: { borderRadius: 24 }, onChange: handleChange, defaultValue: locale },
            React.createElement("option", { value: "en" }, "English"),
            React.createElement("option", { value: "ja" }, "\u65E5\u672C\u8A9E"),
            React.createElement("option", { value: "zh-CN" }, "\u4E2D\u6587 (\u7B80\u4F53)"),
            React.createElement("option", { value: "ko" }, "\uD55C\uAD6D\uC5B4"))));
};
export default Footer;
