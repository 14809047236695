import { groupBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import Modal from '../../components/modal';
import Select from '../../components/select';
import { uploadMissionTm } from '../../lib/api';
import { __, getEnum } from '../../lib/i18n';
const MissionTmUploader = (props) => {
    const { tms: initialTms, availableTmsToImport, availableDocumentsToImport } = props;
    console.log(availableDocumentsToImport);
    const [step, setStep] = useState('main');
    const [tms, setTms] = useState(initialTms);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [sourceLang, setSourceLang] = useState('en');
    const [targetLang, setTargetLang] = useState('en');
    const [visibility, setVisibility] = useState(null);
    const [importSource, setImportSource] = useState('upload');
    const [sourceTmId, setSourceTmId] = useState(null);
    const [sourceDocumentId, setSourceDocumentId] = useState(null);
    const fileInputRef = useRef();
    useEffect(() => {
        if (dialogOpen) {
            setError('');
            setName('');
            setSourceLang('en');
            setTargetLang('en');
            setVisibility(null);
            setImportSource('upload');
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    }, [dialogOpen]);
    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    const handleChangeSourceLang = (e) => {
        setSourceLang(e.target.value);
    };
    const handleChangeTargetLang = (e) => {
        setTargetLang(e.target.value);
    };
    const handleChangeVisibility = (e) => {
        setVisibility(e.target.value);
    };
    const handleChangeImportSource = (e) => {
        setImportSource(e.target.value);
        if (e.target.value === 'upload') {
            setSourceTmId(null);
            setSourceDocumentId(null);
        }
    };
    const handleClickTm = () => {
        setStep('tm');
    };
    const handleClickDocument = () => {
        setStep('document');
    };
    const handleChangeTm = (id) => {
        setSourceTmId(id);
    };
    const handleChangeDocument = (id) => {
        setSourceDocumentId(id);
    };
    const handleClickRegister = () => {
        setDialogOpen(true);
    };
    const handleClickCancel = () => {
        setDialogOpen(false);
    };
    const handleClickBack = () => {
        setStep('main');
    };
    const handleClickSubmit = async () => {
        if (!valid())
            return;
        setSubmitting(true);
        const result = await uploadMissionTm(buildPayload());
        setSubmitting(false);
        result.match((response) => {
            setDialogOpen(false);
            setTms([...tms, response.data]);
        }, (err) => setError(err.message));
    };
    const valid = () => {
        return name && sourceLang && targetLang && visibility;
    };
    const buildPayload = () => {
        const payload = {};
        payload.name = name;
        payload.sourceLang = sourceLang;
        payload.targetLang = targetLang;
        payload.visibility = visibility;
        const fileInput = fileInputRef.current;
        if (!sourceTmId && !sourceDocumentId && fileInput && fileInput.files[0]) {
            const file = fileInput.files[0];
            payload.file = file;
        }
        if (sourceTmId) {
            payload.sourceTmId = sourceTmId.toString();
        }
        if (sourceDocumentId) {
            payload.sourceDocumentId = sourceDocumentId.toString();
        }
        return payload;
    };
    let sourceTm = null;
    if (sourceTmId) {
        const tm = availableTmsToImport.find(({ id }) => id === sourceTmId);
        if (tm) {
            sourceTm = tm;
        }
    }
    let sourceDocument = null;
    if (sourceDocumentId) {
        const document = availableDocumentsToImport.find(({ id }) => id === sourceDocumentId);
        if (document) {
            sourceDocument = document;
        }
    }
    const items = tms.map((tm) => {
        const handleClickRemove = () => {
            setTms((tms) => tms.filter(({ id }) => tm.id !== id));
        };
        return (React.createElement("div", { className: "flex items-center", style: { cursor: 'pointer' } },
            React.createElement("input", { type: "hidden", name: "tms[]", value: tm.id }),
            React.createElement("span", null, tm.name),
            React.createElement("span", { onClick: handleClickRemove },
                React.createElement(Icon, { size: 24, color: "var(--color-attention-primary)" }, "close"))));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex" },
            React.createElement("span", { className: "inline-flex items-center", style: { width: 120 } }, __('terms.tm')),
            React.createElement("div", { className: "flex" }, items)),
        React.createElement(Button, { small: true, onClick: handleClickRegister }, "\u65B0\u898F\u767B\u9332"),
        React.createElement(Modal, { id: "addTmModal", title: "\u7528\u8A9E\u767B\u9332", open: dialogOpen },
            step === 'main' ? (React.createElement(React.Fragment, null,
                error && (React.createElement("div", { className: "alert alert-danger" },
                    React.createElement("ul", null,
                        React.createElement("li", null, error)))),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.name'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("input", { type: "text", className: "form-control", value: name, onChange: handleChangeName })),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.language'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement(Select, { value: sourceLang || '', onChange: handleChangeSourceLang }, Object.entries(getEnum('Lang')).map(([key, value]) => (React.createElement("option", { key: key, value: key }, value)))),
                        React.createElement("div", { className: "mx-4" },
                            React.createElement("i", { className: "glyphicon glyphicon-arrow-right" })),
                        React.createElement(Select, { value: targetLang || '', onChange: handleChangeTargetLang }, Object.entries(getEnum('Lang')).map(([key, value]) => (React.createElement("option", { key: key, value: key }, value)))))),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.visibility'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", null, Object.entries(getEnum('ReferenceVisibility')).map(([key, value]) => (React.createElement("label", { className: "inline-flex items-center mr-3" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: key, checked: visibility === key, onChange: handleChangeVisibility }),
                        React.createElement("span", null, value)))))),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            "\u767B\u9332\u65B9\u6CD5",
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", null, Object.entries(getEnum('TMImportSource')).map(([key, value]) => (React.createElement("label", { className: "inline-flex items-center mr-3" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: key, checked: importSource === key, onChange: handleChangeImportSource }),
                        React.createElement("span", null, value))))),
                    React.createElement("div", { className: "mt-4" }, importSource === 'upload' ? (React.createElement("input", { type: "file", ref: fileInputRef })) : importSource === 'copy' ? (React.createElement("div", null,
                        sourceTm && React.createElement("div", null, sourceTm.name),
                        React.createElement("div", { className: "mt-2 c-link", onClick: handleClickTm },
                            sourceTm && '別の',
                            "\u7FFB\u8A33\u30E1\u30E2\u30EA\u3092\u9078\u629E"))) : (React.createElement("div", null,
                        sourceDocument && React.createElement("div", null, sourceDocument.name),
                        React.createElement("div", { className: "mt-2 c-link", onClick: handleClickDocument },
                            sourceDocument && '別の',
                            "\u6587\u66F8\u3092\u9078\u629E"))))))) : step === 'tm' ? (React.createElement(TmSelector, { tms: availableTmsToImport, value: sourceTmId, onChange: handleChangeTm })) : (React.createElement(DocumentSelector, { documents: availableDocumentsToImport, value: sourceDocumentId, onChange: handleChangeDocument })),
            React.createElement("div", { className: "flex justify-end" }, step === 'main' ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { outlined: true, className: "mr-4", onClick: handleClickCancel }, __('terms.cancel')),
                React.createElement(Button, { disabled: !valid(), onClick: handleClickSubmit, submitting: submitting }, __('terms.register')))) : (React.createElement(Button, { outlined: true, onClick: handleClickBack }, "\u623B\u308B"))))));
};
const TmSelector = (props) => {
    const { tms, value, onChange } = props;
    const group = groupBy(tms, 'visibility');
    const handleChange = (e) => onChange(Number(e.target.value));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { overflow: 'auto', height: 420, padding: 16 }, className: "border border-solid c-border--baseMiddle mb-8" }, Object.entries(group).map(([key, chunkedTms]) => {
            const items = chunkedTms.map((tm) => {
                return (React.createElement("div", { className: "py-2" },
                    React.createElement("label", { className: "c-typo--subBody" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: tm.id.toString(), checked: value === tm.id, onChange: handleChange }),
                        tm.name)));
            });
            return (React.createElement("div", { className: "pb-2" },
                React.createElement("div", { className: "c-typo--labelBold c-color--baseDeep" }, getEnum('ReferenceVisibility')[key]),
                items));
        }))));
};
const DocumentSelector = (props) => {
    const { documents, value, onChange } = props;
    const group = groupBy(documents, 'projectName');
    const handleChange = (e) => onChange(Number(e.target.value));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { overflow: 'auto', height: 420, padding: 16 }, className: "border border-solid c-border--baseMiddle mb-8" }, Object.entries(group).map(([key, chunkedDocuments]) => {
            const items = chunkedDocuments.map((document) => {
                return (React.createElement("div", { className: "py-2" },
                    React.createElement("label", { className: "c-typo--subBody" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: document.id.toString(), checked: value === document.id, onChange: handleChange }),
                        document.name)));
            });
            return (React.createElement("div", { className: "pb-2" },
                React.createElement("div", { className: "c-typo--labelBold c-color--baseDeep" }, key),
                items));
        }))));
};
export default MissionTmUploader;
