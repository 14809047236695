import { Controller } from 'stimulus';
import { createElement } from 'react';
import { render } from 'react-dom';
import * as modules from 'modules';

export default class extends Controller {
  connect() {
    const componentName = this.data.get('name');
    const props = JSON.parse(this.data.get('props'));
    render(createElement(modules[componentName], props), this.element);
  }
}
