import MicroModal from 'micromodal';
import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['tmplModal', 'button', 'submitButton', 'form', 'modalPlaceholder', 'error'];

  connect() {
    this.update();
  }

  get state() {
    return JSON.parse(this.data.get('state'));
  }

  set state(state) {
    this.data.set('state', JSON.stringify(state));
    this.update();
  }

  cancel(e) {
    e.preventDefault();
    MicroModal.close('modal-upload-project-reference-terminology');
    while (this.modalPlaceholderTarget.firstChild) {
      this.modalPlaceholderTarget.removeChild(this.modalPlaceholderTarget.lastChild);
    }
    this.state = { ...this.state, submitting: false, errorMessages: [] };
  }

  openModal() {
    const el = this.tmplModalTarget.content.cloneNode(true);
    this.modalPlaceholderTarget.appendChild(el);
    MicroModal.show('modal-upload-project-reference-terminology', { openClass: 'c-modal--open' });
  }

  submit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.submitting) return;
    const formData = new FormData(this.formTarget);

    this.state = { ...this.state, submitting: true };

    axios
      .post(`/api/projectReferenceTerminologies`, formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        location.reload();
      })
      .catch((err) => {
        const messages = [];
        if (err.response && err.response.data && err.response.data.messages) {
          err.response.data.messages.forEach((message) => {
            messages.push(message);
          });
        } else {
          messages.push('Something wrong occured.');
        }
        this.state = { ...this.state, submitting: false, errorMessages: messages };
      })
      .finally(() => {
        this.state = { ...this.state, submitting: false };
      });
  }

  update() {
    if (this.hasSubmitButtonTarget) {
      const submitButtonInput = this.submitButtonTarget.querySelector('input');

      if (this.state.submitting) {
        submitButtonInput.value = '送信中……';
        submitButtonInput.classList.add('c-button--disabled');
      } else {
        submitButtonInput.value = '登録';
        submitButtonInput.classList.remove('c-button--disabled');
      }
    }

    if (this.hasErrorTarget) {
      const { errorMessages } = this.state;
      if (errorMessages && errorMessages.length > 0) {
        this.errorTarget.classList.remove('hidden');
        const el = this.errorTarget.querySelector('ul');
        while (el.firstChild) {
          el.removeChild(el.lastChild);
        }
        errorMessages.forEach((message) => {
          const li = document.createElement('li');
          li.innerText = message;
          el.appendChild(li);
        });
      } else {
        const el = this.errorTarget.querySelector('ul');
        while (el.firstChild) {
          el.removeChild(el.lastChild);
        }
        this.errorTarget.classList.add('hidden');
      }
    }
  }
}
