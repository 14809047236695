import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['opener'];

  disconnect() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick = (e) => {
    if (e.target.classList.contains('c-menu__item')) return;
    document.removeEventListener('click', this.handleClick);
    this.element.classList.remove('c-menu--open');
  };

  open() {
    this.element.classList.add('c-menu--open');
    setTimeout(() => document.addEventListener('click', this.handleClick));
  }
}
