/**
 * プロジェクトのステート切り替え
 */

import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  connect() {
    console.log('connect');
  }
}
