import { Controller } from 'stimulus';
import Vue from 'vue';
import * as modules from 'modules';

export default class extends Controller {
  connect() {
    const componentName = this.data.get('name');
    const props = JSON.parse(this.data.get('props'));
    const el = document.createElement('div');
    this.element.appendChild(el);
    this.vm = new Vue({
      el,

      components: { ...modules },

      render(h) {
        return h(componentName, { props });
      },
    });
  }
  disconnect() {
    this.vm.$destroy();
  }
}
