import React, { useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { __ } from '../../lib/i18n';
import Chart from './chart';
import { Checkbox } from './checkbox';
import { Panel, PanelBody, PanelHeader } from './panel';
import { Switch } from './switch';
import { MetalangTree } from './metalang_tree';
const App = (props) => {
    const { id, data, metalangNode } = props;
    const categories = {
        issue: __('terms.issue'),
        username: __('terms.userRole'),
        document: __('terms.document'),
    };
    const usernames = uniq(data.map(({ username }) => username));
    const documents = uniq(data.map(({ document }) => document));
    const issues = uniq(data.map(({ issue }) => issue));
    const [base, setBase] = useState('issue');
    const [classes, setClasses] = useState(['username', 'document']);
    const [graphType, setGraphType] = useState('bar');
    const [frequency, setFrequency] = useState('absolute');
    const [panel, setPanel] = useState('integrated');
    const [popups, setPopups] = useState({});
    const [filters, setFilters] = useState({
        username: usernames.reduce((acc, username) => {
            acc[username] = true;
            return acc;
        }, {}),
        document: documents.reduce((acc, document) => {
            acc[document] = true;
            return acc;
        }, {}),
        issue: issues.reduce((acc, issue) => {
            acc[issue] = true;
            return acc;
        }, {}),
    });
    const filteredData = data.filter((row) => filters.username[row.username] &&
        filters.document[row.document] &&
        filters.issue[row.issue]);
    const handleCheckFilter = (key, value, checked) => {
        const updatedFilters = {
            username: { ...filters.username },
            document: { ...filters.document },
            issue: { ...filters.issue },
        };
        updatedFilters[key][value] = checked;
        setFilters(updatedFilters);
    };
    const subCats = Object.keys(categories).filter((cat) => cat !== base);
    useEffect(() => {
        setClasses([...subCats]);
    }, [base]);
    const handleToggleAll = (key, value) => {
        const updatedFilters = {
            username: { ...filters.username },
            document: { ...filters.document },
            issue: { ...filters.issue },
        };
        const values = uniq(data.map((d) => d[key]));
        const updated = values.reduce((acc, v) => {
            acc[v] = value;
            return acc;
        }, {});
        updatedFilters[key] = updated;
        setFilters(updatedFilters);
    };
    useEffect(() => {
        Object.values(popups).map((w) => {
            w.postMessage({
                action: 'update',
                payload: { panel, base, classes, data: filteredData, frequency, graphType },
            });
        });
    }, [panel, base, classes, filteredData, frequency, graphType]);
    const handleStartPopup = () => {
        const params = new URLSearchParams();
        params.set('popup', '1');
        window.open(location.href + '?' + params.toString(), id, 'width=800,height=610');
        window.addEventListener('message', function (e) {
            if (e.data.action === 'ready') {
                if (!e.source)
                    return;
                setPopups({ ...popups, [id]: e.source });
                e.source.postMessage({
                    action: 'update',
                    payload: { panel, base, classes, data: filteredData, frequency, graphType },
                });
            }
        });
    };
    const handleChangeMetalangTree = (id, value) => {
        handleCheckFilter('issue', id, value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '320px 1fr', gap: '16px' } },
            React.createElement("div", null,
                React.createElement("div", { className: "mb-6", style: { height: 192 } },
                    React.createElement(Panel, null,
                        React.createElement(PanelHeader, null,
                            React.createElement("div", { className: "c-typo--heading2" }, __('terms.userRole')),
                            React.createElement("div", { className: "ml-auto" },
                                React.createElement("div", { className: "c-button c-button--small mr-2", onClick: () => handleToggleAll('username', true) },
                                    React.createElement("div", { className: "c-button__container" }, __('terms.selectAll'))),
                                React.createElement("div", { className: "c-button c-button--small c-button--attention", onClick: () => handleToggleAll('username', false) },
                                    React.createElement("div", { className: "c-button__container" }, __('terms.clear'))))),
                        React.createElement(PanelBody, null, usernames.map((username) => (React.createElement("label", { className: "block mb-3", key: username },
                            React.createElement(Checkbox, { value: username, checked: filters.username[username], onChange: (e) => handleCheckFilter('username', e.target.value, e.target.checked) }),
                            React.createElement("span", { className: "ml-2" }, username))))))),
                React.createElement("div", { style: { height: 192 } },
                    React.createElement(Panel, null,
                        React.createElement(PanelHeader, null,
                            React.createElement("div", { className: "c-typo--heading2" }, __('terms.document')),
                            React.createElement("div", { className: "ml-auto" },
                                React.createElement("div", { className: "c-button c-button--small mr-2", onClick: () => handleToggleAll('document', true) },
                                    React.createElement("div", { className: "c-button__container" }, __('terms.selectAll'))),
                                React.createElement("div", { className: "c-button c-button--small c-button--attention", onClick: () => handleToggleAll('document', false) },
                                    React.createElement("div", { className: "c-button__container" }, __('terms.clear'))))),
                        React.createElement(PanelBody, null, documents.map((document) => (React.createElement("label", { className: "block mb-2", key: document },
                            React.createElement(Checkbox, { value: document, checked: filters.document[document], onChange: (e) => handleCheckFilter('document', e.target.value, e.target.checked) }),
                            React.createElement("span", { className: "ml-2" }, document)))))))),
            React.createElement("div", { style: { height: 408 } },
                React.createElement(Panel, null,
                    React.createElement(PanelHeader, null,
                        React.createElement("div", { className: "flex pr-6", style: { width: 432 } },
                            React.createElement("div", { className: "c-typo--heading2" }, __('terms.issue')),
                            React.createElement("div", { className: "ml-auto" },
                                React.createElement("div", { className: "c-button c-button--small mr-2", onClick: () => handleToggleAll('issue', true) },
                                    React.createElement("div", { className: "c-button__container" }, __('terms.selectAll'))),
                                React.createElement("div", { className: "c-button c-button--small c-button--attention", onClick: () => handleToggleAll('issue', false) },
                                    React.createElement("div", { className: "c-button__container" }, __('terms.clear'))))),
                        React.createElement("div", { className: "flex px-6 py-2 items-center", style: { borderLeft: '1px solid var(--color-base-middle)', height: '100%' } },
                            React.createElement("div", { className: "c-typo--heading2" }, __('terms.selection')))),
                    React.createElement("div", { style: { display: 'flex', height: 360 } },
                        React.createElement("div", { className: "px-6 py-4 flex-shrink-0", style: { overflow: 'auto', width: 456 } },
                            React.createElement(MetalangTree, { node: metalangNode, values: Object.entries(filters.issue)
                                    .filter(([_k, v]) => v)
                                    .map(([k, _v]) => k), nodeRenderer: ({ id, name, value }) => (React.createElement(React.Fragment, null,
                                    React.createElement(Checkbox, { value: id, checked: value, onChange: () => handleChangeMetalangTree(id, !value) }),
                                    React.createElement("span", { className: "ml-2" }, name))) })),
                        React.createElement("div", { style: { borderLeft: '1px solid var(--color-base-middle)', flexGrow: 1, padding: 20, overflow: 'auto' } }, issues
                            .filter((issue) => filters.issue[issue])
                            .map((issue) => (React.createElement("div", { className: "mb-1" }, issue)))))))),
        React.createElement("div", { className: "c-typo--heading1 mb-4 mt-8" }, __('terms.graph')),
        React.createElement("div", { className: "flex items-end" },
            React.createElement("div", { className: "mr-4", style: { width: 256 } },
                React.createElement("div", { className: "c-typo--bodyBold mb-4" }, __('terms.base')),
                React.createElement("div", { className: "c-select" },
                    React.createElement("select", { value: base, className: "c-typo--subBodyBold", onChange: (e) => setBase(e.target.value) }, Object.entries(categories).map(([k, v]) => (React.createElement("option", { key: k, value: k }, v)))))),
            React.createElement("div", { className: "mr-4", style: { width: 256 } },
                React.createElement("div", { className: "c-typo--bodyBold mb-4" }, __('terms.class')),
                React.createElement("div", { className: "c-select" },
                    React.createElement("select", { className: "c-typo--subBodyBold", value: classes.join('-'), onChange: (e) => setClasses(e.target.value.split('-')) },
                        React.createElement("option", { value: [subCats[0], subCats[1]].join('-') },
                            categories[subCats[0]],
                            " \u2192 ",
                            categories[subCats[1]]),
                        React.createElement("option", { value: [subCats[1], subCats[0]].join('-') },
                            categories[subCats[1]],
                            " \u2192 ",
                            categories[subCats[0]])))),
            React.createElement("div", { className: "mr-4", style: { width: 256 } },
                React.createElement("div", { className: "c-typo--bodyBold mb-4" }, __('terms.graphType')),
                React.createElement("div", { className: "c-select" },
                    React.createElement("select", { value: graphType, className: "c-typo--subBodyBold", onChange: (e) => setGraphType(e.target.value) },
                        React.createElement("option", { value: "bar" }, __('enums.GraphType.Bar')),
                        React.createElement("option", { value: "stackedBar" }, __('enums.GraphType.StackedBar')),
                        React.createElement("option", { value: "band" }, __('enums.GraphType.Band'))))),
            React.createElement("div", { className: "mr-4" },
                React.createElement(Switch, { values: ['integrated', 'separate'], labels: [__('enums.GraphPanel.Integrated'), __('enums.GraphPanel.Separated')], value: panel, onChange: setPanel })),
            React.createElement("div", null,
                React.createElement(Switch, { values: ['absolute', 'relative'], labels: [__('enums.GraphFrequency.Frequency'), __('enums.GraphFrequency.Ratio')], value: frequency, onChange: setFrequency }))),
        React.createElement("div", { style: { padding: '8px 0' } }),
        React.createElement(Chart, { panel: panel, base: base, classes: classes, data: filteredData, frequency: frequency, graphType: graphType, onStartPopup: handleStartPopup })));
};
export default App;
