import React from 'react';
import { groupBy, sumBy, uniq, uniqBy, max, flatten } from 'lodash';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { __ } from '../../lib/i18n';
const colorGenerator = () => {
    const colors = ['#ffab91', '#ce93d8', '#ffe082', '#ef9a9a', '#9fa8da', '#81d4fa', '#80cbc4', '#a5d6a7', '#e6ee9c'];
    let i = 0;
    const cache = {};
    return (key) => {
        if (key in cache)
            return cache[key];
        const color = colors[++i % colors.length];
        cache[key] = color;
        return color;
    };
};
const genColor = colorGenerator();
const genSeq = (max, step = 1) => {
    const ret = [];
    for (let i = 0; i <= max; i += step) {
        ret.push(i);
    }
    return ret;
};
const IntegratedChart = (props) => {
    const { base, classes, data, frequency, graphType, onStartPopup } = props;
    const total = sumBy(data, 'frequency');
    const graphData = Object.entries(groupBy(data, base)).reduce((acc, [baseValue, rows]) => {
        const tmp = Object.entries(groupBy(rows, classes[0])).map(([class0, rows]) => {
            const subTotal = sumBy(rows, 'frequency');
            return rows.reduce((acc, row) => {
                acc[base] = baseValue;
                acc[classes[0]] = class0;
                if (graphType === 'band') {
                    acc[row[classes[1]]] = subTotal === 0 ? 0 : Math.floor((row.frequency / subTotal) * 1000) / 1000;
                }
                else if (frequency === 'absolute') {
                    acc[row[classes[1]]] = row.frequency;
                }
                else {
                    acc[row[classes[1]]] = Math.floor((row.frequency / total) * 1000) / 1000;
                }
                return acc;
            }, {});
        });
        return [...acc, ...tmp];
    }, []);
    console.log(graphData);
    const dataKeys = uniq(data.map((d) => d[classes[1]]));
    let bars = null;
    if (graphType === 'bar') {
        bars = dataKeys.map((dataKey, i) => React.createElement(Bar, { key: dataKey, dataKey: dataKey, fill: genColor(dataKey) }));
    }
    if (graphType === 'stackedBar') {
        bars = dataKeys.map((dataKey, i) => (React.createElement(Bar, { key: dataKey, stackId: "id", dataKey: dataKey, fill: genColor(dataKey) })));
    }
    if (graphType === 'band') {
        bars = dataKeys.map((dataKey, i) => (React.createElement(Bar, { key: dataKey, stackId: "id", dataKey: dataKey, fill: genColor(dataKey) })));
    }
    const numClasses1 = uniqBy(data, classes[0]).length;
    const numClasses2 = uniqBy(data, classes[1]).length;
    const renderBaseTick = (props) => {
        const { x, y, payload } = props;
        if (payload.index % numClasses1 > 0)
            return React.createElement(React.Fragment, null);
        const transform = `translate(${x - payload.offset}, ${y + 24})`;
        const width = graphType === 'bar' ? 48 * numClasses1 * numClasses2 : 48 * numClasses1;
        return (React.createElement("g", { transform: transform },
            React.createElement("foreignObject", { height: 32, width: width },
                React.createElement("div", { style: {
                        backgroundColor: '#ECF7F6',
                        fontSize: 14,
                        fontWeight: 'bold',
                        borderRadius: 10,
                        margin: '0 8px',
                        padding: '6px 8px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    } }, payload.value))));
    };
    const renderClassSourceTick = (props) => {
        const { x, y, payload } = props;
        const transform = `translate(${x - payload.offset}, ${y + 4})`;
        const width = graphType === 'bar' ? 48 * numClasses2 : 48;
        return (React.createElement("g", { transform: transform },
            React.createElement("foreignObject", { width: width, height: 20 },
                React.createElement("div", { style: {
                        fontSize: 12,
                        margin: '0 8px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    } }, payload.value))));
    };
    const handleClickPopup = () => {
        onStartPopup && onStartPopup();
    };
    const ml = 32;
    const w = graphType === 'bar' ? data.length * 48 + 60 - ml : graphData.length * 48 + 60 - ml;
    let dataMax = 1;
    if (graphType === 'bar') {
        dataMax = max(flatten(graphData.map((data) => dataKeys.map((key) => data[key]))));
    }
    if (graphType === 'stackedBar') {
        dataMax = max(flatten(graphData.map((data) => sumBy(dataKeys, (key) => data[key]))));
    }
    return (React.createElement("div", { style: {
            border: '1px solid #58B7AA',
            borderRadius: 10,
            padding: '12px 16px',
        } },
        React.createElement("div", { className: "flex flex-row-reverse" }, onStartPopup && React.createElement("button", { onClick: handleClickPopup }, __('Open as popup'))),
        React.createElement("div", { style: {
                height: 'max-content',
                overflow: 'auto',
                overflowY: 'hidden',
                padding: 4,
            } },
            React.createElement(BarChart, { width: w, height: 520, barCategoryGap: 16, barGap: 16, barSize: 32, data: graphData, margin: {
                    top: 0,
                    bottom: 40,
                    left: -ml,
                    right: 0,
                } },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                React.createElement(XAxis, { dataKey: classes[0], axisLine: false, tickLine: false, tickSize: 0, interval: 0, tick: renderClassSourceTick, xAxisId: "cs" }),
                React.createElement(XAxis, { dataKey: base, interval: 0, tick: renderBaseTick, height: 1 }),
                React.createElement(YAxis, { ticks: frequency === 'absolute' && graphType !== 'band'
                        ? dataMax === 0
                            ? [0, 5]
                            : genSeq(Math.ceil(dataMax / 5) * 5, 5)
                        : [0, 0.25, 0.5, 0.75, 1], domain: frequency === 'absolute' && graphType !== 'band'
                        ? [0, (dataMax) => Math.ceil(dataMax / 5) * 5]
                        : [0, 1] }),
                React.createElement(Tooltip, null),
                bars))));
};
const SeparateChart = (props) => {
    const { base, data, classes, frequency, graphType } = props;
    const items = Object.entries(groupBy(data, base)).map(([key, data]) => {
        const total = sumBy(data, 'frequency');
        const graphData = Object.entries(groupBy(data, classes[0])).map(([key, rows]) => {
            const subTotal = sumBy(rows, 'frequency');
            return rows.reduce((acc, row) => {
                acc[classes[0]] = key;
                if (graphType === 'band') {
                    acc[row[classes[1]]] = subTotal === 0 ? 0 : Math.floor((row.frequency / subTotal) * 1000);
                }
                else if (frequency === 'absolute') {
                    acc[row[classes[1]]] = row.frequency;
                }
                else {
                    acc[row[classes[1]]] = total === 0 ? 0 : Math.floor((row.frequency / total) * 100) / 100;
                }
                return acc;
            }, {});
        });
        let bars = null;
        const dataKeys = uniq(data.map((d) => d[classes[1]]));
        if (graphType === 'bar') {
            bars = dataKeys.map((dataKey, i) => React.createElement(Bar, { dataKey: dataKey, fill: genColor(dataKey) }));
        }
        if (graphType === 'stackedBar') {
            bars = dataKeys.map((dataKey, i) => React.createElement(Bar, { stackId: "id", dataKey: dataKey, fill: genColor(dataKey) }));
        }
        if (graphType === 'band') {
            bars = dataKeys.map((dataKey, i) => React.createElement(Bar, { stackId: "id", dataKey: dataKey, fill: genColor(dataKey) }));
        }
        const numClasses2 = uniqBy(data, classes[1]).length;
        const renderClassSourceTick = (props) => {
            const { x, y, payload } = props;
            const transform = `translate(${x - payload.offset}, ${y + 4})`;
            const width = graphType === 'bar' ? 48 * numClasses2 : 48;
            return (React.createElement("g", { transform: transform },
                React.createElement("foreignObject", { width: width, height: 20 },
                    React.createElement("div", { style: {
                            fontSize: 12,
                            margin: '0 8px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        } }, payload.value))));
        };
        const ml = 32;
        const w = graphType === 'bar' ? data.length * 48 + 60 - ml : graphData.length * 48 + 60 - ml;
        let dataMax = 1;
        if (graphType === 'bar') {
            dataMax = max(flatten(graphData.map((data) => dataKeys.map((key) => data[key]))));
        }
        if (graphType === 'stackedBar') {
            dataMax = max(flatten(graphData.map((data) => sumBy(dataKeys, (key) => data[key]))));
        }
        return (React.createElement("div", { className: "float-left", key: key, style: {
                maxWidth: 1120,
                overflow: 'auto',
                border: '1px solid #58B7AA',
                borderRadius: 10,
                marginRight: 16,
                marginBottom: 16,
                padding: '12px 16px',
            } },
            React.createElement("div", { className: "c-typo--subBodyBold text-center mb-2", style: { width: w, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }, key),
            React.createElement(BarChart, { width: w, height: 255, barCategoryGap: 16, barGap: 16, barSize: 32, data: graphData, margin: { top: 0, right: 0, bottom: 0, left: -ml } },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                React.createElement(XAxis, { dataKey: classes[0], interval: 0, tick: renderClassSourceTick }),
                React.createElement(YAxis, { ticks: frequency === 'absolute' && graphType !== 'band'
                        ? dataMax === 0
                            ? [0, 5]
                            : genSeq(Math.ceil(dataMax / 5) * 5, 5)
                        : [0, 0.25, 0.5, 0.75, 1], domain: frequency === 'absolute' && graphType !== 'band'
                        ? [0, (dataMax) => Math.ceil(dataMax / 5) * 5]
                        : [0, 1] }),
                React.createElement(Tooltip, null),
                bars)));
    });
    return React.createElement("div", null, items);
};
const Chart = (props) => {
    const { panel, data, ...rest } = props;
    if (data.length === 0)
        return null;
    return panel === 'integrated' ? React.createElement(IntegratedChart, { data: data, ...rest }) : React.createElement(SeparateChart, { data: data, ...rest });
};
export default Chart;
