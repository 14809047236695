import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import { updateTerminology } from '../../lib/api';
const Table = styled.table `
  thead {
    th {
      padding-bottom: 4px;
    }
    td {
      border-top: 1px var(--color-base-middle) solid;
    }
  }

  tbody {
    td {
      height: 36px;
      border: 1px var(--color-base-middle) solid;
      padding: 4px;
    }

    input {
      border: 1px var(--color-base-middle) solid;
    }
  }
`;
const AddButton = styled.tr `
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    height: 36px;
    color: var(--color-theme-dark);
    background-color: var(--color-base-pale);
    cursor: pointer;
  }
`;
const TermEditor = (props) => {
    const { backToUrl, terminologyId, name: defaultName, sLangLabel, tLangLabel, terms: defaultTerms, editable = false, } = props;
    const [terms, setTerms] = useState(defaultTerms.map((t) => ({ ...t, new: false })));
    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState(defaultName);
    const handleChange = (i, key, value) => {
        const term = terms[i];
        setTerms([...terms.slice(0, i), { ...term, [key]: value }, ...terms.slice(i + 1)]);
    };
    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    const handleClickAdd = () => {
        const term = {
            id: `_${Date.now()}`,
            sText: '',
            tText: '',
            comment: '',
            new: true,
        };
        setTerms([...terms, term]);
    };
    const handleClickSubmit = async () => {
        const termsPayload = terms.map((term) => {
            if (term.new)
                return { sText: term.sText, tText: term.tText, comment: term.comment };
            return { id: term.id, sText: term.sText, tText: term.tText, comment: term.comment };
        });
        try {
            setSubmitting(true);
            await updateTerminology(terminologyId, { terms: termsPayload, name });
            location.href = backToUrl;
        }
        catch {
            setSubmitting(false);
        }
    };
    const items = terms.map((term, i) => {
        const handleChangeSText = (e) => {
            handleChange(i, 'sText', e.target.value);
        };
        const handleChangeTText = (e) => {
            handleChange(i, 'tText', e.target.value);
        };
        const handleChangeComment = (e) => {
            handleChange(i, 'comment', e.target.value);
        };
        const handleDelete = () => {
            setTerms([...terms.slice(0, i), ...terms.slice(i + 1)]);
        };
        return (React.createElement("tr", { key: term.id },
            React.createElement("td", { style: { width: 240 } }, editable ? (React.createElement("input", { className: "w-full", type: "text", value: term.sText, onChange: handleChangeSText })) : (term.sText)),
            React.createElement("td", { style: { width: 240 } }, editable ? (React.createElement("input", { className: "w-full", type: "text", value: term.tText, onChange: handleChangeTText })) : (term.tText)),
            React.createElement("td", null, editable ? (React.createElement("input", { className: "w-full", type: "text", value: term.comment, onChange: handleChangeComment })) : (term.comment)),
            editable && (React.createElement("td", { style: { width: 48, textAlign: 'center', cursor: 'pointer' }, onClick: handleDelete },
                React.createElement(Icon, { size: 24, color: "var(--color-theme-dark)" }, "close")))));
    });
    return (React.createElement(React.Fragment, null,
        editable && (React.createElement("div", { className: "c-formGroup" },
            React.createElement("label", { className: "c-label c-typo--bodyBold" },
                "\u540D\u524D",
                React.createElement("span", { className: "c-label__required c-typo--labelBold ml-2" }, "\u5FC5\u9808")),
            React.createElement("input", { type: "text", className: "form-control", value: name, onChange: handleChangeName }))),
        React.createElement(Table, { className: "w-full" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { style: { width: 240 }, className: "c-typo--label c-color--baseDeep" }, sLangLabel),
                    React.createElement("th", { style: { width: 240 }, className: "c-typo--label c-color--baseDeep" }, tLangLabel),
                    React.createElement("th", { className: "c-typo--label c-color--baseDeep" }, "\u30B3\u30E1\u30F3\u30C8"),
                    editable && React.createElement("th", { style: { width: 48 } }))),
            React.createElement("tbody", null, items),
            editable && (React.createElement("tfoot", null,
                React.createElement(AddButton, null,
                    React.createElement("td", { colSpan: 4 },
                        React.createElement("div", { onClick: handleClickAdd },
                            React.createElement(Icon, { size: 14 }, "add"),
                            React.createElement("span", { className: "ml-2" }),
                            "\u65B0\u898F\u8FFD\u52A0")))))),
        editable && (React.createElement("div", { className: "mt-8 flex justify-end" },
            React.createElement("a", { href: backToUrl },
                React.createElement(Button, { outlined: true }, "\u30AD\u30E3\u30F3\u30BB\u30EB")),
            React.createElement(Button, { submitting: submitting, submit: true, className: "ml-4", onClick: handleClickSubmit }, "\u4FDD\u5B58")))));
};
export default TermEditor;
