<template>
  <div>
    <div class="text-right">
      <div class="c-button mt-4" v-on:click.prevent="handleClickAddButton">
        <div class="flex items-center">
          <span class="c-icon">add</span>
          <span>{{ __('Add participants') }}</span>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table max-w-none" style="width: max-content">
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td
              v-for="role in roles"
              :key="role.id"
              class="text-center c-typo--labelBold"
              style="width: 64px; vertical-align: middle"
            >
              {{ role.name }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(participation, i) in participations" :key="participation.userId">
            <td class="c-typo--bodyBold flex items-center">
              <!-- user stateが1は mnhtt/includes/class/DAO.php の T_USER_STATE_QUIT の値と一致する -->
              <span v-if="participation.userState == 1">
                <i class="glyphicon glyphicon-warning-sign warning-sign" title="卒業したユーザーです。"></i>
              </span>
              <span class="c-icon">user</span>
              <span class="truncate ... w-xxxl">{{ participation.userName }}</span>
            </td>
            <td>{{ participation.organizationName }}</td>
            <td>
              <div class="c-checkbox">
                <input
                  type="checkbox"
                  :checked="checkedFlags[i].every((v) => v)"
                  v-on:change.prevent="handleToggleAllRoles(participation.userId)"
                />
                <div class="c-checkbox__frame"></div>
              </div>
            </td>
            <td v-for="(role, j) in roles" :key="role.id" class="text-center">
              <div class="c-checkbox">
                <input
                  v-on:change="handleToggle(participation.userId, role.id)"
                  v-model="checkedFlags[i][j]"
                  type="checkbox"
                  :name="`roles[${participation.userId}][]`"
                  :value="role.id"
                />
                <div class="c-checkbox__frame"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="c-modal" id="modal-update-participants" aria-hidden="true">
      <div class="c-modal__overlay" tabindex="-1" data-micromodal-close>
        <div class="c-modal__container" role="dialog" aria-modal="true">
          <h2 class="c-modal__title c-typo--heading2">{{ __('Add participants') }}</h2>
          <div class="c-modal__contents">
            <div class="p-4 border border-solid" style="border-color: var(--color-base-middle)">
              <div v-for="group in registrantGroup" v-bind:key="group.name" class="mb-6">
                <div class="c-typo--labelBold mb-4" style="color: var(--color-base-deep)">{{ group.name }}</div>
                <div class="flex flex-wrap">
                  <div
                    class="c-participantsCard c-typo--bodyBold mr-2 mb-2"
                    v-on:click="handleToggleRegistrant(registrant.id)"
                    v-bind:class="{ [activeClass]: selection[registrant.id] }"
                    v-bind:key="registrant.id"
                    v-for="registrant in group.registrants"
                  >
                    <span class="c-icon">user</span>
                    {{ registrant.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <div class="c-button c-button--outlined" v-on:click.prevent="handleClickClose">
                <div class="c-button__container">閉じる</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Micromodal from 'micromodal';
import { groupBy } from 'lodash';

import { __ } from '../lib/i18n';

export default {
  data() {
    return {
      participations: this.defaultParticipations,
      activeClass: 'c-participantsCard--active',
    };
  },

  computed: {
    checkedFlags() {
      return this.participations.map(({ roleIds }) => this.roles.map(({ id }) => roleIds.includes(id)));
    },

    selection() {
      return this.participations.reduce((acc, p) => {
        acc[p.userId] = true;
        return acc;
      }, {});
    },

    registrantGroup() {
      const g = groupBy(this.registrants, 'type.id');
      return Object.keys(g)
        .sort()
        .reverse()
        .map((key) => {
          const registrants = g[key];
          return {
            name: registrants[0].type.name,
            registrants,
          };
        });
    },

    registrantIndex() {
      return this.registrants.reduce((acc, registrant) => {
        acc[registrant.id] = registrant;
        return acc;
      }, {});
    },
  },

  methods: {
    __(key) {
      return __(key);
    },

    handleToggleAllRoles(userId) {
      this.participations = this.participations.map((p) => {
        if (p.userId !== userId) return p;
        if (p.roleIds.length === this.roles.length) {
          return { ...p, roleIds: [] };
        } else {
          return { ...p, roleIds: this.roles.map(({ id }) => id) };
        }
      });
    },

    handleClickClose() {
      Micromodal.close();
    },

    handleClickAddButton() {
      Micromodal.show('modal-update-participants', { openClass: 'c-modal--open' });
    },

    handleToggle(userId, roleId) {
      this.participations = this.participations.map((p) => {
        if (p.userId !== userId) return p;
        if (p.roleIds.includes(roleId)) {
          const roleIds = p.roleIds.filter((val) => val !== roleId);
          return { ...p, roleIds };
        } else {
          const roleIds = [...p.roleIds, roleId];
          return { ...p, roleIds };
        }
      });
    },

    handleToggleRegistrant(userId) {
      const participated = this.participations.find((p) => p.userId === userId);
      if (participated) {
        this.participations = this.participations.filter((p) => p.userId !== userId);
        return;
      }
      const registrant = this.registrantIndex[userId];
      this.participations = [...this.participations, { userId: registrant.id, userName: registrant.name, roleIds: [] }];
    },
  },

  props: {
    defaultParticipations: Array,

    registrants: Array,

    roles: Array,
  },
};
</script>
