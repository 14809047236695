import MicroModal from 'micromodal';
import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'tmplModal',
    'tmplResourceItem',
    'button',
    'cancelButton',
    'submitButton',
    'glossaryList',
    'form',
    'modalPlaceholder',
    'error',
  ];

  connect() {
    this.update();
  }

  get state() {
    return JSON.parse(this.data.get('state'));
  }

  set state(state) {
    this.data.set('state', JSON.stringify(state));
    this.update();
  }

  cancel(e) {
    e.preventDefault();
    MicroModal.close('modal-upload-glossary');
    while (this.modalPlaceholderTarget.firstChild) {
      this.modalPlaceholderTarget.removeChild(this.modalPlaceholderTarget.lastChild);
    }
    this.state = { ...this.state, submitting: false, errorMessages: [] };
  }

  openModal() {
    const el = this.tmplModalTarget.content.cloneNode(true);
    this.modalPlaceholderTarget.appendChild(el);
    MicroModal.show('modal-upload-glossary', { openClass: 'c-modal--open' });
  }

  removeResourceItem(e) {
    const id = Number(e.currentTarget.dataset.id);
    this.state = {
      ...this.state,
      glossaries: this.state.glossaries.filter((glossary) => glossary.id !== id),
    };
  }

  submit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.submitting) return;
    const formData = new FormData(this.formTarget);

    this.state = { ...this.state, submitting: true };

    axios
      .post('/api/glossaries', formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        this.state = {
          ...this.state,
          glossaries: [...this.state.glossaries, { id: response.data.id, title: response.data.name }],
        };
        MicroModal.close('modal-upload-glossary');
      })
      .catch((err) => {
        const messages = [];
        if (err.response && err.response.data && err.response.data.messages) {
          err.response.data.messages.forEach((message) => {
            messages.push(message);
          });
        } else {
          messages.push('Something wrong occured.');
        }
        this.state = { ...this.state, submitting: false, errorMessages: messages };
      })
      .finally(() => {
        this.state = { ...this.state, submitting: false };
      });
  }

  update() {
    if (this.hasSubmitButtonTarget && this.hasCancelButtonTarget) {
      const submitButtonInput = this.submitButtonTarget.querySelector('input');
      const cancelButtonInput = this.cancelButtonTarget.querySelector('input');

      if (this.state.submitting) {
        submitButtonInput.value = '送信中……';
        submitButtonInput.classList.add('c-button--disabled');
        cancelButtonInput.classList.add('c-button--disabled');
      } else {
        submitButtonInput.value = '登録';
        submitButtonInput.classList.remove('c-button--disabled');
        cancelButtonInput.classList.remove('c-button--disabled');
      }
    }

    if (this.hasErrorTarget) {
      const { errorMessages } = this.state;
      if (errorMessages && errorMessages.length > 0) {
        this.errorTarget.classList.remove('hidden');
        const el = this.errorTarget.querySelector('ul');
        while (el.firstChild) {
          el.removeChild(el.lastChild);
        }
        errorMessages.forEach((message) => {
          const li = document.createElement('li');
          li.innerText = message;
          el.appendChild(li);
        });
      } else {
        const el = this.errorTarget.querySelector('ul');
        while (el.firstChild) {
          el.removeChild(el.lastChild);
        }
        this.errorTarget.classList.add('hidden');
      }
    }

    while (this.glossaryListTarget.firstChild) {
      this.glossaryListTarget.removeChild(this.glossaryListTarget.lastChild);
    }

    this.state.glossaries.forEach((glossary) => {
      const el = this.tmplResourceItemTarget.content.firstElementChild.cloneNode(true);

      el.querySelector('.tmpl-title').innerText = glossary.title;
      el.querySelector('input').setAttribute('name', 'glossaries[]');
      el.querySelector('input').setAttribute('value', glossary.id);
      el.dataset.id = glossary.id;
      this.glossaryListTarget.appendChild(el);
    });
  }
}
