import MicroModal from 'micromodal';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import { initialize as initializeI18n, __ } from './lib/i18n';

import './styles/app.sass';

// 古いコードでも翻訳ができるようglobalオブジェクトに露出させておく
window.__ = __;

document.addEventListener('DOMContentLoaded', () => {
  MicroModal.init({ openClass: 'c-modal--open' });
  initializeI18n();
});

const application = Application.start();
const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
