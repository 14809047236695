import { groupBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/button';
import { Checkbox } from '../../components/checkbox';
import { Icon } from '../../components/icon';
import Modal from '../../components/modal';
import Select from '../../components/select';
import { uploadMissionTerminology } from '../../lib/api';
import { __, getEnum } from '../../lib/i18n';
const MissionTerminologyUploader = (props) => {
    const { terminologies: initialTerminologys, availableTerminologiesToImport } = props;
    const [step, setStep] = useState('main');
    const [terminologys, setTerminologys] = useState(initialTerminologys);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState(Object.keys(getEnum('ReferenceType'))[0]);
    const [submitting, setSubmitting] = useState(false);
    const [sourceLang, setSourceLang] = useState('en');
    const [targetLang, setTargetLang] = useState('en');
    const [type, setType] = useState('1');
    const [editableByProject, setEditableByProject] = useState(false);
    const [visibility, setVisibility] = useState(null);
    const [importSource, setImportSource] = useState('upload');
    const [sourceTerminologyId, setSourceTerminologyId] = useState(null);
    const fileInputRef = useRef();
    useEffect(() => {
        if (dialogOpen) {
            setError('');
            setName('');
            setSourceLang('en');
            setTargetLang('en');
            setType('1');
            setEditableByProject(false);
            setVisibility(null);
            setImportSource('upload');
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    }, [dialogOpen]);
    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    const handleChangeSourceLang = (e) => {
        setSourceLang(e.target.value);
    };
    const handleChangeTargetLang = (e) => {
        setTargetLang(e.target.value);
    };
    const handleChangeType = (e) => {
        setType(e.target.value);
    };
    const handleChangeEditableByProject = () => {
        setEditableByProject((bool) => !bool);
    };
    const handleChangeVisibility = (e) => {
        setVisibility(e.target.value);
    };
    const handleChangeImportSource = (e) => {
        setImportSource(e.target.value);
        if (e.target.value === 'upload') {
            setSourceTerminologyId(null);
        }
    };
    const handleClickTerminology = () => {
        setStep('terminology');
    };
    const handleChangeTerminology = (id) => {
        setSourceTerminologyId(id);
    };
    const handleClickRegister = () => {
        setDialogOpen(true);
    };
    const handleClickCancel = () => {
        setDialogOpen(false);
    };
    const handleClickBack = () => {
        setStep('main');
    };
    const handleClickSubmit = async () => {
        if (!valid())
            return;
        setSubmitting(true);
        const result = await uploadMissionTerminology(buildPayload());
        setSubmitting(false);
        result.match((response) => {
            setDialogOpen(false);
            setTerminologys([...terminologys, response.data]);
        }, (err) => setError(err.message));
    };
    const valid = () => {
        console.log(name, sourceLang, targetLang, type, visibility);
        return name && sourceLang && targetLang && type && visibility;
    };
    const buildPayload = () => {
        const payload = {};
        payload.name = name;
        payload.sourceLang = sourceLang;
        payload.targetLang = targetLang;
        payload.type = type;
        payload.visibility = visibility;
        if (editableByProject) {
            payload.editableByProject = '1';
        }
        const fileInput = fileInputRef.current;
        if (!sourceTerminologyId && fileInput && fileInput.files[0]) {
            const file = fileInput.files[0];
            payload.file = file;
        }
        if (sourceTerminologyId) {
            payload.sourceTerminologyId = sourceTerminologyId.toString();
        }
        return payload;
    };
    let sourceTerminology = null;
    if (sourceTerminologyId) {
        const t = availableTerminologiesToImport.find(({ id }) => id === sourceTerminologyId);
        if (t) {
            sourceTerminology = t;
        }
    }
    const items = terminologys.map((terminology) => {
        const handleClickRemove = () => {
            setTerminologys((terminologys) => terminologys.filter(({ id }) => terminology.id !== id));
        };
        return (React.createElement("div", { className: "flex items-center", style: { cursor: 'pointer' } },
            React.createElement("input", { type: "hidden", name: "terminologies[]", value: terminology.id }),
            React.createElement("span", null, terminology.name),
            React.createElement("span", { onClick: handleClickRemove },
                React.createElement(Icon, { size: 24, color: "var(--color-attention-primary)" }, "close"))));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex" },
            React.createElement("span", { className: "inline-flex items-center", style: { width: 120 } }, __('terms.terminology')),
            React.createElement("div", { className: "flex" }, items)),
        React.createElement(Button, { small: true, onClick: handleClickRegister }, "\u65B0\u898F\u767B\u9332"),
        React.createElement(Modal, { id: "addTerminologyModal", title: "\u7528\u8A9E\u767B\u9332", open: dialogOpen },
            step === 'main' ? (React.createElement(React.Fragment, null,
                error && (React.createElement("div", { className: "alert alert-danger" },
                    React.createElement("ul", null,
                        React.createElement("li", null, error)))),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.name'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("input", { type: "text", className: "form-control", value: name, onChange: handleChangeName })),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.language'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement(Select, { value: sourceLang || '', onChange: handleChangeSourceLang }, Object.entries(getEnum('Lang')).map(([key, value]) => (React.createElement("option", { key: key, value: key }, value)))),
                        React.createElement("div", { className: "mx-4" },
                            React.createElement("i", { className: "glyphicon glyphicon-arrow-right" })),
                        React.createElement(Select, { value: targetLang || '', onChange: handleChangeTargetLang }, Object.entries(getEnum('Lang')).map(([key, value]) => (React.createElement("option", { key: key, value: key }, value)))))),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.type'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", null,
                        Object.entries(getEnum('ReferenceType')).map(([key, value]) => (React.createElement("label", { key: key, className: "inline-flex items-center mr-3" },
                            React.createElement("input", { className: "mr-2", type: "radio", value: key, checked: type === key, onChange: handleChangeType }),
                            React.createElement("span", null, value)))),
                        type === '2' &&
                            React.createElement("label", { className: "inline-flex items-center" },
                                React.createElement(Checkbox, { checked: editableByProject, onChange: handleChangeEditableByProject }),
                                React.createElement("span", { className: "ml-2" }, "\u30D7\u30ED\u30B8\u30A7\u30AF\u30C8\u3067\u7528\u8A9E\u96C6\u306E\u5909\u66F4\u3092\u8A31\u53EF"))),
                    React.createElement("div", { className: "mt-2" })),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            __('terms.visibility'),
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", null, Object.entries(getEnum('ReferenceVisibility')).map(([key, value]) => (React.createElement("label", { className: "inline-flex items-center mr-3" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: key, checked: visibility === key, onChange: handleChangeVisibility }),
                        React.createElement("span", null, value)))))),
                React.createElement("div", { className: "c-formGroup" },
                    React.createElement("div", null,
                        React.createElement("label", { className: "c-label c-typo--bodyBold" },
                            "\u767B\u9332\u65B9\u6CD5",
                            React.createElement("span", { className: "c-label__required c-typo--labelBold" },
                                " ",
                                __('terms.required')))),
                    React.createElement("div", null, Object.entries(getEnum('TerminologyImportSource')).map(([key, value]) => (React.createElement("label", { className: "inline-flex items-center mr-3" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: key, checked: importSource === key, onChange: handleChangeImportSource }),
                        React.createElement("span", null, value))))),
                    React.createElement("div", { className: "mt-4" }, importSource === 'upload' ? (React.createElement("input", { type: "file", ref: fileInputRef })) : (React.createElement("div", null,
                        sourceTerminology && React.createElement("div", null, sourceTerminology.name),
                        React.createElement("div", { className: "mt-2 c-link", onClick: handleClickTerminology },
                            sourceTerminology && '別の',
                            "\u7528\u8A9E\u96C6\u3092\u9078\u629E"))))))) : (React.createElement(React.Fragment, null,
                React.createElement(TerminologySelector, { terminologies: availableTerminologiesToImport, value: sourceTerminologyId, onChange: handleChangeTerminology }))),
            React.createElement("div", { className: "flex justify-end" }, step === 'main' ? (React.createElement(React.Fragment, null,
                ' ',
                React.createElement(Button, { outlined: true, className: "mr-4", onClick: handleClickCancel }, __('terms.cancel')),
                React.createElement(Button, { disabled: !valid(), onClick: handleClickSubmit, submitting: submitting }, __('terms.register')))) : (React.createElement(Button, { outlined: true, onClick: handleClickBack }, "\u623B\u308B"))))));
};
const TerminologySelector = (props) => {
    const { terminologies, value, onChange } = props;
    const group = groupBy(terminologies, 'visibility');
    const handleChange = (e) => onChange(Number(e.target.value));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { overflow: 'auto', height: 420, padding: 16 }, className: "border border-solid c-border--baseMiddle mb-8" }, Object.entries(group).map(([key, chunkedTerminologies]) => {
            const items = chunkedTerminologies.map((terminology) => {
                return (React.createElement("div", { className: "py-2" },
                    React.createElement("label", { className: "c-typo--subBody" },
                        React.createElement("input", { className: "mr-2", type: "radio", value: terminology.id.toString(), checked: value === terminology.id, onChange: handleChange }),
                        terminology.name)));
            });
            return (React.createElement("div", { className: "pb-2" },
                React.createElement("div", { className: "c-typo--labelBold c-color--baseDeep" }, getEnum('ReferenceVisibility')[key]),
                items));
        }))));
};
export default MissionTerminologyUploader;
