import MicroModal from 'micromodal';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    console.log('Hello, Stimulus!', this.element);
  }

  cancel(e) {
    e.preventDefault();
    MicroModal.close('modal-upload-term');
  }

  submit(e) {
    e.preventDefault();
    MicroModal.close('modal-upload-term');
  }
}
