import React, { useState } from 'react';
export const MetalangTree = (props) => {
    const { node, values, nodeRenderer } = props;
    const [open, setOpen] = useState(true);
    const handleClickToggle = () => {
        setOpen(!open);
    };
    return (React.createElement("div", { className: node.level > 1 ? 'ml-6' : '' },
        node.level > 0 && (React.createElement(MetalangNode, { key: node.id, name: node.name, value: node.id, checked: values.includes(node.id), depth: node.level - 1, leaf: node.children.length === 0, open: open, onClickToggle: handleClickToggle, nodeRenderer: nodeRenderer })),
        open &&
            node.children.map((childNode) => (React.createElement(MetalangTree, { key: childNode.id, node: childNode, values: values, onClickToggle: handleClickToggle, nodeRenderer: nodeRenderer })))));
};
const MetalangNode = (props) => {
    const { checked, name, value, leaf, open, depth, onClickToggle, nodeRenderer } = props;
    return (React.createElement("div", { className: "mb-3" },
        !leaf && React.createElement(AccordionButton, { open: open, onClick: onClickToggle }),
        React.createElement("label", { key: value, className: leaf ? 'ml-7' : 'ml-3' }, nodeRenderer({ id: value, name, value: checked }))));
};
const AccordionButton = (props) => {
    const { open, onClick } = props;
    return (React.createElement("span", { style: {
            width: 16,
            height: 16,
            lineHeight: '16px',
            display: 'inline-block',
            cursor: 'pointer',
            verticalAlign: 'middle',
        }, onClick: onClick },
        React.createElement("span", { style: {
                display: 'inline-block',
                color: 'var(--color-theme-dark)',
                transform: open ? 'scaleY(0.5)' : 'scaleX(0.5) rotate(270deg)',
            } }, "\u25BC")));
};
