import { get } from 'lodash';
let data = null;
export const initialize = () => {
    const str = document.body.dataset.i18nDict;
    if (!str)
        return;
    data = JSON.parse(str);
    console.log(data);
};
export const getEnum = (key) => data.enums[key] || {};
export const __ = (key) => {
    if (!data)
        throw new Error();
    const value = get(data, key.split('.'));
    if (value)
        return value;
    return data.texts[key] || key;
};
