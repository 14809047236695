import axios from 'axios';
import { ResultAsync } from 'neverthrow';
export const updateTerminology = (id, payload) => {
    return axios.patch(`/api/glossaries/${id}`, payload);
};
export const updateTm = (id, payload) => {
    return axios.patch(`/api/tms/${id}`, payload);
};
export const uploadProjectFile = (projectId, title, file, type) => {
    const formData = new FormData();
    formData.append('projectId', projectId);
    formData.append('title', title);
    formData.append('type', type);
    formData.append('file', file);
    return ResultAsync.fromPromise(axios.post('/api/projectFiles', formData), () => {
        return new Error('something wrong occured');
    });
};
export const uploadAsset = (title, file) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);
    return ResultAsync.fromPromise(axios.post('/api/assets', formData), () => {
        return new Error('something wrong occured');
    });
};
export const uploadMissionTerminology = (payload) => {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('type', payload.type);
    if (payload.editableByProject) {
        formData.append('editableByProject', '1');
    }
    formData.append('sourceLang', payload.sourceLang);
    formData.append('targetLang', payload.targetLang);
    formData.append('visibility', payload.visibility);
    if (payload.file) {
        formData.append('file', payload.file);
    }
    if (payload.sourceTerminologyId) {
        formData.append('sourceTerminologyId', payload.sourceTerminologyId);
    }
    return ResultAsync.fromPromise(axios.post('/api/missionTerminologies', formData), () => {
        return new Error('something wrong occured');
    });
};
export const uploadMissionTm = (payload) => {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('sourceLang', payload.sourceLang);
    formData.append('targetLang', payload.targetLang);
    formData.append('visibility', payload.visibility);
    if (payload.file) {
        formData.append('file', payload.file);
    }
    if (payload.sourceTmId) {
        formData.append('sourceTmId', payload.sourceTmId);
    }
    if (payload.sourceDocumentId) {
        formData.append('sourceDocumentId', payload.sourceDocumentId);
    }
    return ResultAsync.fromPromise(axios.post('/api/tms', formData), () => {
        return new Error('something wrong occured');
    });
};
