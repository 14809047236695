import React from 'react';
import { Icon } from './icon';
const Select = (props) => {
    const { children, value, required = false, onChange } = props;
    return (React.createElement("div", { className: "c-select" },
        React.createElement("select", { className: "c-typoe--subBodyBold", value: value, onChange: onChange }, children),
        React.createElement("div", { className: " c-select__icon" },
            React.createElement(Icon, null, "chevron_downward"))));
};
export default Select;
