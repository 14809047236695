/**
 * プロジェクトのステート切り替え
 */

import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  async changeState(e) {
    const projectId = this.data.get('projectId');
    try {
      await axios.put(`/api/projects/${projectId}`, { state: e.target.value });
      location.reload();
    } catch (err) {
      console.log(err);
    }
  }
}
