import React from 'react';
export const Panel = (props) => {
    const { className, children } = props;
    return (React.createElement("div", { className: className, style: { borderRadius: 10, border: '1px solid var(--color-base-middle)', height: '100%' } }, children));
};
export const PanelHeader = (props) => {
    const { children } = props;
    return (React.createElement("div", { className: "flex items-center px-6", style: {
            borderRadius: '10px 10px 0 0',
            borderBottom: '1px solid var(--color-base-middle)',
            backgroundColor: 'var(--color-base-pale)',
            height: 48,
        } }, children));
};
export const PanelBody = (props) => {
    const { children } = props;
    return React.createElement("div", { className: "px-6 py-3" }, children);
};
