import { createElement } from 'react';
import { render } from 'react-dom';
import { Controller } from 'stimulus';
import App from '../modules/metalang_stats';

export default class extends Controller {
  static targets = ['root'];

  connect() {
    const props = JSON.parse(this.rootTarget.dataset.moduleProps);

    render(createElement(App, props), this.rootTarget);
  }
}
