import React from 'react';
import classNames from 'classnames';
export const Button = (props) => {
    const { className, outlined = false, small = false, disabled = false, submit = false, submitting = false, onClick, children, } = props;
    const buttonClass = classNames(className, {
        'c-button': true,
        'c-button--input': true,
        'c-button--outlined': outlined,
        'c-button--small': small,
        'c-button--disabled': disabled,
    });
    return (React.createElement("button", { className: buttonClass, style: { display: 'inline-flex', alignItems: 'center' }, type: submit ? 'submit' : 'button', disabled: disabled || submitting, onClick: onClick }, submitting ? '送信中……' : children));
};
