import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'checkboxToggleAll'];

  toggle(e) {
    const inputEls = this.checkboxTargets.filter((el) => e.target.dataset.userId === el.dataset.userId);
    const allChecked = inputEls.every((el) => el.checked);
    const checkboxToggleAllEl = this.checkboxToggleAllTargets.find(
      (el) => e.target.dataset.userId === el.dataset.userId
    );
    checkboxToggleAllEl.checked = allChecked;
  }

  toggleAll(e) {
    const inputEls = this.checkboxTargets.filter((el) => e.target.dataset.userId === el.dataset.userId);
    const checked = !inputEls.every((el) => el.checked);
    inputEls.forEach((el) => (el.checked = checked));
  }
}
